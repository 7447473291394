import Vue from 'vue';
import VModal from 'vue-js-modal';
import VCalendar from 'v-calendar';
import { AngieUI } from '@fc/angie-ui';
import { FCAnalytics, ShopifyService } from '@fc/app-common';
import apiClient from '@/shared/scripts/api_client';
import { createStorefrontApiClient } from '@shopify/storefront-api-client';
import fetch from 'isomorphic-fetch';
import App from './App.vue';
import AppManager from './AppManager';
import http from './http';
import apps from '../apps';
import { Auth0Plugin } from './auth';
import '@fc/angie-ui/dist/styles/index.css';
import ordersService from '../apps/bizDevPos/ordersService';

Vue.use(VCalendar);
Vue.use(VModal, { dynamic: true });
Vue.use(AngieUI);
Vue.use(FCAnalytics, {
  writeKey: process.env.VUE_APP_SEGMENT,
});
Vue.prototype.$ordersApi = ordersService;
const shopifyClient = createStorefrontApiClient({
  storeDomain: process.env.VUE_APP_SHOPIFY_STORE_DOMAIN || '',
  apiVersion: process.env.VUE_APP_SHOPIFY_API_VERSION || '',
  publicAccessToken: process.env.VUE_APP_SHOPIFY_STOREFRONT_TOKEN,
  customFetchApi: fetch,
});
const eCommService = new ShopifyService(shopifyClient);
Vue.prototype.$eCommService = eCommService;

Vue.filter('currency', value => `$${parseFloat(value / 100.0).toFixed(2)}`);
Vue.filter('phone', phone => phone.replace(/[^0-9]/g, ''));

const appManager = new AppManager(apps);

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState) => {
    appManager.router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
}, (auth) => {
  auth.atoken.then(console.log);
  appManager.deploy(auth);
  http.setAuthHeader(auth);
});

Vue.config.productionTip = false;
Vue.prototype.$apps = appManager;
Vue.prototype.$http = http.client;
Vue.prototype.$api_client = apiClient;

new Vue({
  router: appManager.router,
  store: appManager.store,

  render: (h) => h(App),
}).$mount('#app');
