<template>
  <svg
    :width="width"
    height="100%"
    viewBox="0 0 180 144"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>FightCamp Logo</title>
    <desc>FightCamp Logo</desc>
    <g
      id="FC_Logo_Horizontal"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd">
      <polyline
        id="Fill-1"
        :fill="color"
        points="52.735 64.633 46.126 64.633 46.126 26.083 62.648 26.083 62.648 31.81 52.735 31.81 52.735 42.75 60.445 42.75 60.445 48.478 52.735 48.478 52.735 64.633" />
      <polygon
        id="Fill-2"
        :fill="color"
        points="64.959 64.633 71.568 64.633 71.568 26.083 64.959 26.083" />
      <polyline
        id="Fill-3"
        :fill="color"
        points="104.059 48.442 104.059 64.633 97.45 64.633 97.45 26.083 104.059 26.083 104.059 42.714 110.006 42.714 110.006 26.083 116.615 26.083 116.615 64.633 110.006 64.633 110.006 48.442 104.059 48.442" />
      <polyline
        id="Fill-4"
        :fill="color"
        points="131.208 64.633 124.655 64.633 124.655 31.81 118.707 31.81 118.707 26.083 137.156 26.083 137.156 31.81 131.208 31.81 131.208 64.633" />
      <polygon
        id="Fill-5"
        :fill="color"
        points="26.764 19.474 153.236 19.474 153.236 18 26.764 18" />
      <polygon
        id="Fill-6"
        :fill="color"
        points="26.764 72.738 153.236 72.738 153.236 71.264 26.764 71.264" />
      <polygon
        id="Fill-7"
        :fill="color"
        points="26.764 126 153.236 126 153.236 124.527 26.764 124.527" />
      <path
        id="Fill-8"
        d="M94.092,55.822 C94.092,61.274 91.008,65.129 84.785,65.129 C78.451,65.129 75.092,61.163 75.092,55.877 L75.092,34.84 C75.092,29.553 78.451,25.588 84.785,25.588 C91.008,25.588 94.092,29.443 94.092,34.895 L94.092,39.521 L87.813,39.521 L87.813,34.84 C87.813,33.023 86.988,31.37 84.785,31.37 C82.582,31.37 81.756,33.023 81.756,34.84 L81.756,55.877 C81.756,57.694 82.582,59.347 84.785,59.347 C86.988,59.347 87.813,57.694 87.813,55.877 L87.813,48.415 L84.564,48.415 L84.564,42.771 L94.092,42.771 L94.092,55.822"
        :fill="color" />
      <path
        id="Fill-9"
        d="M57.196,104.126 L63.474,104.126 L63.474,109.082 C63.474,114.534 60.39,118.39 54.167,118.39 C47.834,118.39 44.474,114.424 44.474,109.137 L44.474,88.1 C44.474,82.813 47.834,78.848 54.167,78.848 C60.39,78.848 63.474,82.703 63.474,88.155 L63.474,92.395 L57.196,92.395 L57.196,88.1 C57.196,86.283 56.37,84.631 54.167,84.631 C51.964,84.631 51.138,86.283 51.138,88.1 L51.138,109.137 C51.138,110.954 51.964,112.607 54.167,112.607 C56.37,112.607 57.196,110.954 57.196,109.137 L57.196,104.126"
        :fill="color" />
      <path
        id="Fill-10"
        d="M93.817,117.949 L87.538,117.949 L87.538,79.344 L96.853,79.344 L100.974,103.941 L101.085,103.941 L105.21,79.344 L114.524,79.344 L114.524,117.949 L108.245,117.949 L108.245,106.934 C108.245,101.232 108.484,94.946 108.755,89.439 C108.759,89.362 108.762,89.285 108.766,89.207 L103.737,117.949 L98.326,117.949 L93.359,89.176 C93.634,94.738 93.817,101.152 93.817,106.934 L93.817,117.949"
        :fill="color" />
      <path
        id="Fill-11"
        d="M125.262,117.893 L118.653,117.893 L118.653,79.344 L127.684,79.344 C133.633,79.344 137.542,83.199 137.542,89.587 L137.542,92.23 C137.542,98.619 133.687,102.529 127.684,102.529 L125.262,102.529 L125.262,117.893 Z M125.262,96.801 L127.244,96.801 C129.612,96.801 130.989,95.094 130.989,92.23 L130.989,89.642 C130.989,86.778 129.612,85.071 127.244,85.071 L125.262,85.071 L125.262,96.801 L125.262,96.801 Z"
        :fill="color" />
      <path
        id="Fill-12"
        d="M85.337,117.893 L78.674,117.893 L77.682,110.899 L71.844,110.899 L70.853,117.893 L64.63,117.893 L70.633,79.344 L79.334,79.344 L85.337,117.893 Z M74.873,89.303 L72.56,105.998 L77.021,105.998 L74.873,89.303 L74.873,89.303 Z"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
import { red, bluetint } from '@fc/angie-ui/dist/styles/_js_exports.scss';

export default {
  name: 'FightCampLogo',
  props: {
    width: {
      type: String,
      default: '80px',
    },
    white: Boolean,
    gray: Boolean,
    red: Boolean,
    blue: Boolean,
    black: Boolean,
  },
  computed: {
    color() {
      if (this.red) { return red; }
      if (this.white) { return '#FFFFFF'; }
      if (this.gray) { return '#434343'; }
      if (this.blue) { return bluetint; }
      if (this.black) { return '#000000'; }
      return '#212121';
    },

  },
};
</script>
