export default {
  id: 'spend',
  name: 'Marketing Spend',
  description: 'Send marketing spend information to Segment',
  permission: 'spend:app',
  image: './patrick.png',
  version: '0.1.0',
  routes: [{
    path: '/spend',
    name: 'Spend',
    component: () => import('./Spend.vue'),
  }],
};
