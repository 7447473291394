import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import hdyhau from '@/shared/store/survey';
import Home from './views/Home.vue';
import Profile from './views/Profile.vue';
import NotFound from './views/404.vue';
import appStore from './appStore';

Vue.use(Vuex);
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
];

export default class AppManager {
  constructor(apps) {
    this.registered = apps;
    this.available = [];
    this.store = new Vuex.Store();
    this.router = new VueRouter({
      mode: 'history',
      base: process.env.BASE_URL,
      routes,
    });
  }

  register(app) {
    this.registeredApps.push(app);
  }

  deploy(auth) {
    this.registered.forEach((app) => {
      const granted = auth.permissions.includes(app.permission);
      if (granted) {
        this.router.addRoutes([...app.routes]);
        if (app.store) {
          this.store.registerModule(app.id, app.store);
        }
        this.available.push(app);
      }
    });
    this.router.addRoutes([{
      path: '*',
      name: '404',
      component: NotFound,
    }]);

    // module names must match names used in other apps
    this.store.registerModule('survey', hdyhau);
    this.store.registerModule('app', appStore);
  }
}
