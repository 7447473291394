<template>
  <div class="layout-nav">
    <router-link class="layout-nav-logo" to="/">
      <FightCampLogo
        width="6rem"
        height="6rem"
        red />
    </router-link>
    <router-link class="layout-nav-grid-icon" to="/">
      <font-awesome-icon icon="th" />
    </router-link>
    <div class="layout-nav-spacer" />
    <router-link to="/profile">
      <img class="layout-nav-user" :src="$auth.user.picture">
    </router-link>
    <a class="layout-nav-logout" @click="logout">
      <font-awesome-icon icon="sign-out-alt" />
    </a>
  </div>
</template>

<script>
  import FightCampLogo from '@/shared/components/FightCampLogo.vue';

  export default {
    name: 'LayoutNav',
    components: {
      FightCampLogo,
    },
    methods: {
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      },
    },
  };
</script>

<style scoped lang='scss'>
  .layout-nav {
    position: sticky;
    background-color: $color-blue;
    top: 0;
    min-height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;

    &-spacer {
      flex: 1 1 auto;
    }

    &-logo {
      margin: 1rem 0.5rem;
    }

    &-icon {
      margin: 1rem 0.5rem;
      width: 5rem;
      height: 5rem;
      object-fit: cover;
      border-radius: $container-radius;
    }

    &-grid-icon {
      display: flex;
      justify-content: center;
      align-content: center;
      margin: 1rem 0.5rem;
      font-size: 3rem;
    }

    &-user {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
    }

    &-logout {
      display: flex;
      justify-content: center;
      align-content: center;
      margin: 2rem 0.5rem;
      font-size: 3rem;
    }
  }

</style>
