import surveys from './surveys';
import spend from './spend';
import coupons from './coupons';
import bizDevPos from './bizDevPos';
import gen2Registration from './gen2Registration';

export default [
  surveys,
  spend,
  coupons,
  bizDevPos,
  gen2Registration,
];
