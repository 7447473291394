import http from '@/core/http';
import dayjs from 'dayjs';
import urls from './data/urls';

const url = process.env.VUE_APP_PRODUCTION ? urls.production : urls.development;

export default {
  namespaced: true,
  state: {
    isLoading: false,
    surveys: [],
    interval: 'day',
    periodIndex: 0,
    customDates: {
      start: new Date(),
      end: new Date(),
    },
    bucketFilters: [],
  },
  mutations: {
    CLEAR_SURVEYS(state) {
      state.surveys = [];
    },
    SET_SURVEYS(state, surveys) {
      state.surveys = surveys.map((survey) => ({ ...survey, selected: false }));
    },
    UPDATE_SURVEY_ATTRIBUTION(state, data) {
      const found = state.surveys.find((survey) => survey.externalId === data.extId);
      if (found) {
        found.attribution = data.attribution;
      }
    },
    UPDATE_SURVEY_STATUS(state, data) {
      const found = state.surveys.find((survey) => survey.externalId === data.extId);
      if (found) {
        found.status = data.status;
      }
    },
    TOGGLE_BUCKET_FILTER(state, bucket) {
      const found = state.bucketFilters.find((filter) => filter === bucket.key);
      if (found) {
        state.bucketFilters = state.bucketFilters.filter((filter) => filter !== bucket.key);
      } else {
        state.bucketFilters.push(bucket.key);
      }
    },
    UPDATE_INTERVAL(state, interval) {
      state.interval = interval;
      state.periodIndex = 0;
    },
    DECREMENT_PERIOD(state) {
      state.periodIndex -= 1;
    },
    INCREMENT_PERIOD(state) {
      state.periodIndex += 1;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_CUSTOM_DATES(state, customDates) {
      state.customDates = customDates;
    },
  },
  getters: {
    surveys(state) {
      return state.surveys;
    },
    isLoading(state) {
      return state.isLoading;
    },
    selectedInterval(state) {
      return state.interval;
    },
    periodIndex(state) {
      return state.periodIndex;
    },
    currentPeriod(state) {
      if (state.interval === 'custom') {
        return {
          startdate: dayjs(state.customDates.start),
          enddate: dayjs(state.customDates.end),
        };
      }
      return {
        startdate: dayjs().subtract(state.periodIndex, state.interval).startOf(state.interval),
        enddate: dayjs().subtract(state.periodIndex, state.interval).endOf(state.interval),
      };
    },
    currentQuery(state, getters) {
      if (state.interval === 'day') {
        return `date=${getters.currentPeriod.startdate.format('YYYY-MM-DD')}`;
      }
     return `startdate=${getters.currentPeriod.startdate.format('YYYY-MM-DD')}&enddate=${getters.currentPeriod.enddate.format('YYYY-MM-DD')}`;
    },
    isBucketFilterActive: (state) => (bucket) => !!state.bucketFilters.find((filter) => filter === bucket.key),
    bucketFilters: (state) => state.bucketFilters,

  },
  actions: {
    async fetchByDate({ commit, getters }) {
      try {
        commit('SET_LOADING', true);
        commit('CLEAR_SURVEYS');
        const query = getters.currentQuery;
        const { data } = await http.client.get(`${url}/responses/heardaboutus?${getters.currentQuery}`);
        if (query === getters.currentQuery) {
          commit('SET_SURVEYS', data.data);
          commit('SET_LOADING', false);
        }
      } catch (e) {
        console.error(e);
        commit('SET_LOADING', false);
      }
    },

    async updateAttribution({ commit }, data) {
      try {
        await http.client.post(`${url}/responses/${data.extId}`, { attribution: data.attribution, status: data.status });
        commit('UPDATE_SURVEY_ATTRIBUTION', data);
        commit('UPDATE_SURVEY_STATUS', data);
      } catch (e) {
        console.error(e);
      }
    },

    async changeInterval({ commit, dispatch }, interval) {
      try {
        commit('UPDATE_INTERVAL', interval);
        await dispatch('fetchByDate');
      } catch (e) {
        console.error(e);
      }
    },

    async incrementPeriod({ commit, dispatch }) {
      try {
        commit('INCREMENT_PERIOD');
        await dispatch('fetchByDate');
      } catch (e) {
        console.error(e);
      }
    },

    async decrementPeriod({ state, commit, dispatch }) {
      if (!state.periodIndex) {
        return;
      }
      try {
        commit('DECREMENT_PERIOD');
        await dispatch('fetchByDate');
      } catch (e) {
        console.error(e);
      }
    },
    async setCustomDates({ commit, dispatch }, range) {
      try {
        commit('SET_CUSTOM_DATES', range);
        await dispatch('fetchByDate');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
