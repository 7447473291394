import store from './store';

export default {
  id: 'checkout',
  name: 'BizDev',
  description: 'Point of sale for business development',
  permission: 'bizdev:app',
  icon: 'fa-cash-register',
  version: '0.1.0',
  routes: [
    {
      path: '/bizDev',
      name: 'BizDev',
      component: () => import('./BizDev.vue'),
    },
    {
      path: '/bizDev/confirmation',
      name: 'PostPurchase',
      component: () => import('./BizDev_PostPurchase.vue'),
    },
  ],
  store,
};
