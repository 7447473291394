<template>
  <router-link class="appcard" :to="path">
    <div class="appcard__icon">
      <font-awesome-icon v-if="!!icon" :icon="icon" />
      <img
        v-else
        class="appcard__image"
        :src="image">
    </div>
    <div class="appcard__block">
      <h2 class="appcard__name">
        {{ name }}
      </h2>
      <p class="appcard__description">
        {{ description }}
      </p>
      <p v-if="version" class="appcard__version">
        v{{ version }}
      </p>
    </div>
  </router-link>
</template>

<script>
  export default {
    name: 'AppCard',
    props: {
      name: {
        type: String,
        default: 'Unknown',
      },
      description: {
        type: String,
        default: '',
      },
      path: {
        type: String,
        default: '/',
      },
      icon: {
        type: String,
        default: '',
      },
      version: {
        type: String,
        default: '',
      },
      image: {
        type: String,
        default: '',
      },
    },
  };

</script>

<style scoped lang="scss">
  .appcard {
    background-color: $color-white;
    margin: 1rem;
    padding: 2rem 1rem;
    border-radius: $container-radius;
    display: flex;
    flex-direction: row;
    flex: 0 1 35rem;
    box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.1), 0 0.6rem 2rem 0 rgba(0, 0, 0, 0);
    text-decoration: none;
    color: $color-black;
    text-align: left;

    &__name {
      margin: 0 1rem;
    }

    &__description {
      flex: 1 1 auto;
      margin: 0 1rem;
    }

    &__block {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__icon {
      flex: 0 0 6rem;
      margin: 0 1rem;
      width: 6rem;
      height: 6rem;
      object-fit: cover;
      border-radius: $container-radius;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      background-color: $color-light-gray;
      color: $color-blue;
    }

    &__version {
      margin: 0 1rem;
      color: $color-dark-gray;
      font-family: $font-stack-bold;
      text-align: right;
    }

    &__image {
      width: 100%;
    }
  }
</style>
