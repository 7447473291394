<template>
  <div class="home">
    <h1 class="home-title">
      INSPIRE THE FIGHTER WITHIN
    </h1>

    <div v-if="$apps.available.length" class="home-row">
      <AppCard
        v-for="app in $apps.available"
        :key="app.name"
        :name="app.name"
        :icon="app.icon"
        :image="app.image"
        :description="app.description"
        :version="app.version"
        :path="app.routes[0].path" />
    </div>
    <div v-else class="home-row">
      <h3 class="home-empty">
        You don't have access to any apps.
      </h3>
    </div>
  </div>
</template>

<script>
  import AppCard from '../components/AppCard.vue';

  export default {
    name: 'Home',
    components: {
      AppCard,
    },
  };
</script>

<style scoped lang="scss">
  .home {
    max-width: 111rem;
    margin: 2rem auto;

    &-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-title {
      color: $color-light-gray-3;
    }

    &-empty {
      text-align: center;
    }
  }
</style>
