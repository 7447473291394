import products from '@/shared/datasets/variants';
import newStore from '@/shared/store/newStore';
import actions from './actions';
import mutations from './mutations';

export default {
  state: {
    CURRENT_VERSION: 100,
    cart: [],
    cartSyncCompleted: false,
    cartCoupon: null,
    cartCouponError: null,
    cartUrlCouponMessage: null,
    cartDrawerOpen: false,

    amountoff: null,
    percentageoff: null,

    membershipTaxes: null,

    processingTotalCost: false,

    contact: null,
    shippingAddress: null,

    contactEmail: '',
    contactPhoneNumber: '',
    contactError: {
      message: '',
      element: '',
    },
    contactValid: {
      email: true,
      phoneNumber: true,
    },

    email: null,
    shippingInfo: null,

    shippingFirstName: '',
    shippingLastName: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingCity: '',
    shippingPostalCode: '',
    shippingCountry: 'US',
    shippingState: '',
    shippingError: {
      message: '',
      element: '',
    },
    shippingValid: {
      firstName: true,
      lastName: true,
      address: true,
      city: true,
      state: true,
      postalCode: true,
      country: true,
    },

    shippingMethod: '',
    shippingMethodError: {
      message: '',
      element: '',
    },

    order: null,
    customerId: null,
    confirmation: null,
    new_customer: false,
    affirmCheckoutToken: null,
    stripeToken: null,
    affirmToken: null,
    paymentMethod: null,
    membershipType: {
      type: 'fightcampplanC',
      key: 'FightCamp_1_MonthC',
      name: 'Monthly',
    },
    currentUser: null,
    userToken: null,
    dealItems: [],
    accessoryCoupons: {
      EXTRA50: 14900,
      EXTRA20: 7500,
      GEARUP30: 3000,
      GEARUP75: 7500,
      GEARUP150: 15000,
    },
    ...newStore.state,
  },

  mutations: {
    ...mutations,
    ...newStore.mutations,
  },
  actions: {
    ...actions,
    ...newStore.actions,
  },

  getters: {
    ...newStore.getters,
    CURRENT_VERSION: state => state.CURRENT_VERSION,

    /**
     *  Cart Checkout
     */
    cart: state => state.cart,
    cartSyncCompleted: state => state.cartSyncCompleted,
    cartCoupon: state => state.cartCoupon,
    cartCouponError: state => state.cartCouponError,
    cartUrlCouponMessage: state => state.cartUrlCouponMessage,
    cartDrawerOpen: state => state.cartDrawerOpen,

    /**
     *  Checkout
     */
    currentUser: state => state.currentUser,
    shippingAddress: state => state.shippingAddress,
    contact: state => state.contact,
    contactEmail: state => state.contactEmail || state.checkoutEmail,
    processingTotalCost: state => state.processingTotalCost,
    stripeToken: state => state.stripeToken,
    amountoff: state => state.amountoff,
    order: state => state.order,
    confirmation: state => state.confirmation,
    affirmCheckoutToken: state => state.affirmCheckoutToken,
    paymentMethod: state => state.paymentMethod,
    affirmToken: state => state.affirmToken,
    initStarted: state => state.initStarted,
    contactError: state => state.contactError,
    contactValid: state => state.contactValid,
    shippingError: state => state.shippingError,
    shippingValid: state => state.shippingValid,
    shippingMethod: state => state.shippingMethod,
    shippingMethodError: state => state.shippingMethodError,
    membershipTaxes: state => state.membershipTaxes,

    shipping: state => ({
      email: state.contactEmail,
      phoneNumber: state.contactPhoneNumber,

      firstName: state.shippingFirstName,
      lastName: state.shippingLastName,
      address: state.shippingAddress1,
      address2: state.shippingAddress2,
      city: state.shippingCity,
      state: state.shippingState,
      postalCode: state.shippingPostalCode,
      country: state.shippingCountry,
    }),

    cartqty: state => state.cart.reduce((acc, i) => acc + i.qty, 0),
    accessoryCoupons: state => state.accessoryCoupons,
    canApplyAccessoryCoupon: (state, getters) => (coupon) => {
      const isAccessoryCoupon = Object.keys(state.accessoryCoupons).includes(coupon);
      return isAccessoryCoupon && getters.accessoryTotal > 0;
    },
    accessoryTotal: state => state.cart.reduce((acc, item) => {
      const accessories = [
        'customGloves_V2_S',
        'customGloves_V2_L',
        'customGloves_V2_K',
        'quickWraps_V2_S',
        'quickWraps_V2_L',
        'quickWraps_3p_SSS',
        'quickWraps_3p_LLL',
        'quickWraps_3p_SSL',
        'quickWraps_3p_LLS',
        'fightcamp_traditional_wraps',
        'fightcamp_weighted_quick_wraps',
        'fightcamp_tracker_sleeves',
        'fightcamp_bag_ring_bpi',
        'fightcamp_premium_flooring',
        'fightcamp_yoga_mat',
        'fightcamp_agility_ladder',
        'fightcamp_heart_rate_monitor',
        'fightcamp_water_bottle',
        'fightcamp_resistance_bands',
        'fightcamp_jump_rope',
        'fightcamp_laundry_bag',
        'fightcamp_glove_freshener',
        'fightcamp_glove_bag',
        'fightcamp_hdmi_cable',
        'fightcamp_shi_apple_cable',
      ];

      const accessory = accessories.find(accessoryName => accessoryName === item.id);

      if (accessory) {
        const accessoryObject = products.find(product => product.id === accessory);
        const amountToAdd = accessoryObject.price * item.qty;
        return acc + amountToAdd;
      }
      return acc;
    }, 0),
    bundle: state => state.cart.reduce((acc, item) => {
      const desc = products.find(product => item.id === product.id);
      if (!desc) {
        console.log(item);
        return acc;
      }
      desc.id = item.id;
      desc.qty = item.qty;
      desc.type = item.type;
      acc.push(desc);
      return acc;
    }, []),
    hasPackageWithBag: (_, getters) => getters.bundle.find(product => product.items && product.items.find(item => item.name === 'Free-Standing Bag')),
    hasRestrictedItemsInCart: (state, getters) => getters.bundle.find(item => item.shippingRestricted),
    hasPartialCanadaRestrictedItemsInCart: (_, getters) => !!getters.bundle.find(item => item.partialCaShippingRestricted),
    shippingRestricted: (state, getters) => (!!getters.hasRestrictedItemsInCart) && (state.shippingState === 'AK' || state.shippingState === 'HI'),
    checkFieldsNotFilled: (state) => {
      if (state.shippingFirstName === '') {
        return 'shippingFirstName';
      }
      if (state.shippingLastName === '') {
        return 'shippingLastName';
      }
      if (state.shippingAddress1 === '') {
        return 'shippingAddress1';
      }
      if (state.shippingCity === '') {
        return 'shippingCity';
      }
      if (state.shippingPostalCode === '') {
        return 'shippingPostalCode';
      }
      if (state.shippingCountry === '') {
        return 'shippingCountry';
      }
      if (state.shippingState === '') {
        return 'shippingState';
      }
      return null;
    },
    isShippingFilled: (state, getters) => (getters.shipping.firstName
      && getters.shipping.lastName
      && getters.shipping.address
      && getters.shipping.city
      && getters.shipping.postalCode
      && getters.shipping.country
      && getters.shipping.state),

    isContactFilled: (state, getters) => (getters.shipping.phoneNumber && getters.shipping.email),

    summary_subtotal: (state, getters) => {
      let subtotal = 0;
      if (state.order != null) {
        return state.order.costBreakdown.productCost;
      }
      getters.bundle.forEach((item) => {
        subtotal += item.qty * item.price;
      });
      return subtotal;
    },
    summary_tax: (state) => {
      if (state.order) {
        return state.order.costBreakdown.productTax;
      }
      return null;
    },
    summary_shipping: (state) => {
      if (state.order) {
        return state.order.costBreakdown.productShipping;
      }
      return null;
    },
    summary_coupon: (state, getters) => {
      if (state.order) {
        return state.order.costBreakdown.discountAmount;
      }
      if (state.amountoff) {
        return state.amountoff;
      }
      if (state.percentageoff) {
        return -1 * getters.summary_subtotal * state.percentageoff / 100;
      }
      return null;
    },
    summary_total: (state, getters) => {
      if (state.order) {
        return state.order.costBreakdown.totalCost;
      }
      if (state.percentageoff) {
        return getters.summary_subtotal - getters.summary_subtotal * state.percentageoff / 100;
      }
      return getters.summary_subtotal - getters.amountoff;
    },
    summaryTotalAffirm: (state, getters) => {
      if (!getters.isSubsInCart) {
        return getters.summary_total;
      }

      if (getters.isSubsInCart && state.order && state.paymentMethod === 'stripe') {
        return state.order.costBreakdown.totalCost + 93600;
      }

      if (state.paymentMethod === 'affirm') {
        return getters.summary_total;
      }

      return getters.summary_subtotal - getters.amountoff + 93600;
    },
    isSubsInCart: (state, getters) => {
      let found;
      found = state.cart.find(item => item.type === 'subs' || item.type === 'subs_only');

      if (!found && getters.confirmation) {
        found = getters.confirmation.cart.find(item => item.type === 'subs' || item.type === 'subs_only');
      }

      return !!found;
    },
    isEquipInCart: (state) => {
      const found = state.cart.find(item => item.type === 'equip');
      return !!found;
    },
    packageInCart: (_, getters) => !!getters.cartProducts.packageInCart,
    packageInCartName: (state) => {
      const productInCart = products.find(product => state.cart.find(item => item.id === product.id && product.subscriptionNeeded));

      return productInCart ? productInCart.productName : null;
    },
    isConnect: (_, getters) => getters.bundle.find(item => (['connect_V2_L', 'connect_V2_S'].includes(item.id))),

    orderID: (state) => {
      if (state.order === null) {
        return null;
      }
      return state.order.orderID;
    },
  },
};
