export default {
  id: 'gen2Registration',
  name: 'GEN2 Registration',
  description: 'Register Gen2 Beta Testers in the provision database.',
  permission: 'gen2Registration:app',
  icon: 'fa-barcode',
  version: '0.1.0',
  routes: [{
    path: '/gen2Registration',
    name: 'Gen2Registration',
    component: () => import('./Gen2Registration.vue'),
  }],
};
