<template>
  <div id="app">
    <Layout v-if="!$auth.loading && $auth.isAuthenticated">
      <router-view />
    </Layout>
    <modals-container />
  </div>
</template>
<script>
  import Layout from './components/Layout.vue';

  export default {
    name: 'App',
    components: {
      Layout,
    },
    methods: {
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      },
    },
  };
</script>
<style lang='scss'>

html {
  background-color: $color-light-gray;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
