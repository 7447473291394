
import axios from 'axios';
import EventBus from '@/shared/scripts/eventbus';
import heardAboutUs from '@/shared/datasets/hdyhau';

const urlSurvey = '/api/surveys';

function emitSurveyUpdate(state) {
  const prefix = state.slides[0].surveyId;
  EventBus.$emit('surveyUpdate', state.slides.reduce((acc, slide, index) => {
    if (index === 0) {
      return acc;
    }
    const obj = acc;
    obj[`${prefix}_${index}_id`] = slide.id;
    return obj;
  }, {}));
}

export default {
  state: {
    uid: null,
    completed: false,
    surveyCurrentPos: 0,
    orderNumber: 0,
    orderId: 0,
    email: '',
    externalId: '',
    slides: [],
    upgradeFormCompleted: false,
    upgradeSuccessful: false,
    skipSurveyEvent: false,
   },
   getters: {
    surveyCurrentPos(state) {
      return state.surveyCurrentPos;
    },
    surveySlides(state) {
      return state.slides;
    },
    surveyCurrentSlide(state) {
      if (state.slides.length < state.surveyCurrentPos) {
        return {};
      }
      return state.slides[state.surveyCurrentPos] || {};
    },
    surveyCompleted(state) {
      return state.completed;
    },
    surveyLink(state) {
      if (!state.externalId) {
        return 'Not Available';
      }
      return `https://joinfightcamp.com/surveys/?svid=${state.externalId}`;
    },
   },
   mutations: {
    SET_ORDER_INFO(state, {
      orderNumber, orderId, email, uid, skipSurveyEvent,
    }) {
      state.orderNumber = orderNumber;
      state.orderId = orderId;
      state.email = email;
      state.uid = uid;
      state.skipSurveyEvent = skipSurveyEvent;
    },
    SET_SURVEY(state, survey) {
      state.surveyCurrentPos = 0;
      state.slides = [survey];
      state.surveyId = survey.surveyId;
    },
    SET_EXTERNAL_ID(state, externalId) {
      state.externalId = externalId;
    },
    GOTO_NEXT_SLIDE(state, nextSlide) {
      state.surveyCurrentPos += 1;
      state.slides.push(nextSlide);
      emitSurveyUpdate(state);
    },
    GOTO_PREVIOUS_SLIDE(state) {
     state.surveyCurrentPos -= 1;
     state.slides.pop();
    },
    COMPLETE_SURVEY(state) {
      state.completed = true;
    },
    COMPLETE_UPGRADE_FORM(state) {
      state.upgradeFormCompleted = true;
    },
    MARK_UPGRADE_SUCCESSFUL(state) {
      state.upgradeSuccessful = true;
    },
    updateCustomerId(state, customerId) {
      state.customerId = customerId;
    },
  },
  actions: {
    async createSurvey({ commit, state }, data) {
      state.completed = false;
      if(!state.skipSurveyEvent) {
        commit('SET_SURVEY', data);
        const resp = await axios.post(`${urlSurvey}/responses/`, {
            surveyId: state.surveyId,
            orderNumber: state.orderNumber,
            email: state.email,
            orderId: state.orderId,
            uid: state.uid,
        });
        commit('SET_EXTERNAL_ID', resp.data.externalId);
      }
    },
    async updateSurvey({ state }, data) {
      await axios.post(`${urlSurvey}/responses/${state.externalId}`, {
        value: data.value,
        details: data.details.reduce((acc, ans) => `${acc}${ans}; `, ''),
        attribution: data.attribution,
        status: data.status,
      });
    },
    createSurveyEvent({ commit, state }, data) {
      return axios.post(`${urlSurvey}/events/`, {
        email: state.email,
        value: data.value,
        survey: data.survey,
        uid: state.uid,
      });
    },
    async retrieveSurvey({ commit, state }, externalId) {
      commit('SET_EXTERNAL_ID', externalId);
      try {
        const resp = await axios.get(`${urlSurvey}/responses/${state.externalId}`);
        if (resp.data.data.attribution !== null) {
          return {
            id: 'end',
            type: 'message',
            question: 'You have succesfully answer the survey',
            label: 'Go to HomePage',
            path: '/',
          };
        }
        commit('SET_ORDER_INFO', resp.data.data);
        if (resp.data.data.survey === 'heardaboutus') {
          commit('SET_ORDER_INFO', resp.data.data);
          return heardAboutUs;
        }
      } catch (err) {
        console.log(err);
      }
      return {
        id: 'end',
        type: 'message',
        question: 'Sorry, Something went wrong...',
        label: 'Go to homepage',
        path: '/',
      };
    },
  },
};
