export default {
  id: 'coupons',
  name: 'Automated Coupon System',
  description: 'Create new coupons for eCommerce',
  permission: 'coupons:app',
  image: './kevin.png',
  version: '0.1.0',
  routes: [{
    path: '/coupons',
    name: 'Coupons',
    component: () => import('./Coupons.vue'),
  }],
};
