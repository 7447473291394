import axios from 'axios';
import store from '@fc/shared/src/store';
import shopifyStore from './shopifyStore';
import orders from './orders';

export default {
  ...store,
  state: {
    ...store.state,
    ...shopifyStore.state,
    receiptEmail: '',
    paymentIntentId: null,
    checkoutId: null,
    draftOrderId: null,
  },
  mutations: {
    ...store.mutations,
    ...shopifyStore.mutations,
    updatereceiptEmail(state, val) {
      state.receiptEmail = val;
    },
    setOrderIds(state, { draftOrderId, paymentIntentId }) {
      state.paymentIntentId = paymentIntentId;
      state.draftOrderId = draftOrderId;
    },
    setCheckoutId(state, id) {
      state.checkoutId = id;
    },
  },
  actions: {
    ...store.actions,
    ...shopifyStore.actions,
    ...orders.actions,
    async sendReceiptEmail({ state, getters }) {
      const { receiptEmail, confirmation } = state;

      const itemsPurchased = getters.bundle.map(item => item.productName);
      const totalCost = `$${parseFloat(confirmation.order.costBreakdown.totalCost / 100.0).toFixed(2)}`;

      const payload = {
        email: receiptEmail,
        action: 'Purchase Receipt | API',
        properties: {
          orderNumber: confirmation.orderNumber,
          products: itemsPurchased,
          totalCost,
        },
      };

      await axios.post('/.netlify/functions/sendBizDevReceipt', payload);
    },
  },
  getters: {
    ...store.getters,
    ...shopifyStore.getters,
    cart: state => state.cart,
    cartCoupon: state => state.cartCoupon,
    contactEmail: state => state.contactEmail,
    processingTotalCost: state => state.processingTotalCost,
    contactError: state => state.contactError,
    shippingValid: state => state.shippingValid,
    shippingError: state => state.shippingError,
    cartCouponError: state => state.cartCouponError,
    isShopifyOn: () => process.env.VUE_APP_SHOPIFY_ON === 'true',
    getPayemntIntentId: state => state.paymentIntentId,
    summary_subtotal: (state, getters) => {
      if (getters.isShopifyOn) {
        if (!state.order) {
          return getters.cartProducts.shopifyItems.reduce((acc, item) => acc + item.variant.price * item.variant.qty, 0);
        }
        return state.order.costBreakdown.productCost;
      }

      let subtotal = 0;
      if (state.order != null) {
        return state.order.costBreakdown.productCost;
      }
      getters.bundle.forEach((item) => {
        subtotal += item.qty * item.price;
      });
      return subtotal;
    },
    summary_tax: (state) => {
      if (state.order) {
        return state.order.costBreakdown.productTax;
      }
      return null;
    },
    summary_shipping: (state) => {
      if (state.order) {
        return state.order.costBreakdown?.productShipping || null;
      }
      return null;
    },
    summary_coupon: (state, getters) => {
      if (state.order) {
        return state.order.costBreakdown.discountAmount;
      }
      if (state.amountoff) {
        return state.amountoff;
      }
      if (state.percentageoff) {
        return -1 * getters.summary_subtotal * state.percentageoff / 100;
      }
      return null;
    },
    summary_total: (state, getters) => {
      if (state.order) {
        return state.order.costBreakdown.totalCost;
      }
      if (state.percentageoff) {
        return getters.summary_subtotal - getters.summary_subtotal * state.percentageoff / 100;
      }
      return getters.summary_subtotal - getters.amountoff;
    },
    summaryDuties: (state) => {
      if (state.order) {
        return state.order.totalDuties;
      }
      return null;
    },
    includesConsole: (_, getters) => getters.cartProducts?.packageInCart?.product?.tags?.includes('includesConsole') ?? false,
  },
};
