import store from './store';

export default {
  id: 'surveys',
  name: 'Surveys Management',
  description: 'View and update internal survey',
  permission: 'surveys:app',
  icon: 'poll-h',
  version: '0.4.0',
  routes: [{
    path: '/surveys',
    name: 'Surveys',
    component: () => import('./Surveys.vue'),
  }],
  store,
};
