export default {
  state: {
    representative: '',
    location: '',
  },
  mutations: {
    setRepresentative(state, representative) {
      state.representative = representative;
    },
    setLocation(state, location) {
      state.location = location;
    },
  },
};
