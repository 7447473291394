<template>
  <div class="layout">
    <LayoutNav />
    <div class="layout-body">
      <slot />
    </div>
  </div>
</template>

<script>
  import LayoutNav from './LayoutNav.vue';

  export default {
    name: 'Layout',
    components: {
      LayoutNav,
    },
    methods: {
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      },
    },
  };
</script>

<style scoped lang='scss'>
  .layout {
    height: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &-body {
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-color: $color-light-gray;
    }
  }
</style>
