import axios from 'axios';

const apiClient = axios.create({
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  client: apiClient,
  setAuthHeader(auth) {
    apiClient.interceptors.request.use(async (_config) => {
      if (!auth) {
        return _config;
      }
      const config = _config;
      const token = await auth.getTokenSilently();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  },
};
